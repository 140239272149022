<template>
  <router-link :to="params.link" class="link-cell">
    {{ params.value }}
  </router-link>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.link-cell {
  color: #1a73e8;
  text-decoration: none;
  cursor: pointer;
}
.link-cell:hover {
  text-decoration: underline;
}
</style>
